export const FooterBottom = () => import('../../components/FooterBottom.vue' /* webpackChunkName: "components/footer-bottom" */).then(c => wrapFunctional(c.default || c))
export const FooterMain = () => import('../../components/FooterMain.vue' /* webpackChunkName: "components/footer-main" */).then(c => wrapFunctional(c.default || c))
export const HeaderNavigation = () => import('../../components/HeaderNavigation.vue' /* webpackChunkName: "components/header-navigation" */).then(c => wrapFunctional(c.default || c))
export const UiStyleGuide = () => import('../../components/ui/StyleGuide.vue' /* webpackChunkName: "components/ui-style-guide" */).then(c => wrapFunctional(c.default || c))
export const WidgetsScrollProgress = () => import('../../components/widgets/ScrollProgress.vue' /* webpackChunkName: "components/widgets-scroll-progress" */).then(c => wrapFunctional(c.default || c))
export const BuilderBlogFeaturedPosts = () => import('../../components/builder/blog/FeaturedPosts.vue' /* webpackChunkName: "components/builder-blog-featured-posts" */).then(c => wrapFunctional(c.default || c))
export const BuilderCareersList = () => import('../../components/builder/careers/List.vue' /* webpackChunkName: "components/builder-careers-list" */).then(c => wrapFunctional(c.default || c))
export const BuilderContentButtonCallToAction = () => import('../../components/builder/content/ButtonCallToAction.vue' /* webpackChunkName: "components/builder-content-button-call-to-action" */).then(c => wrapFunctional(c.default || c))
export const BuilderContentButtonSection = () => import('../../components/builder/content/ButtonSection.vue' /* webpackChunkName: "components/builder-content-button-section" */).then(c => wrapFunctional(c.default || c))
export const BuilderContentCaseStudySlider = () => import('../../components/builder/content/CaseStudySlider.vue' /* webpackChunkName: "components/builder-content-case-study-slider" */).then(c => wrapFunctional(c.default || c))
export const BuilderContentClientGrid = () => import('../../components/builder/content/ClientGrid.vue' /* webpackChunkName: "components/builder-content-client-grid" */).then(c => wrapFunctional(c.default || c))
export const BuilderContentColourPalette = () => import('../../components/builder/content/ColourPalette.vue' /* webpackChunkName: "components/builder-content-colour-palette" */).then(c => wrapFunctional(c.default || c))
export const BuilderContentCompareSlider = () => import('../../components/builder/content/CompareSlider.vue' /* webpackChunkName: "components/builder-content-compare-slider" */).then(c => wrapFunctional(c.default || c))
export const BuilderContent = () => import('../../components/builder/content/Content.vue' /* webpackChunkName: "components/builder-content" */).then(c => wrapFunctional(c.default || c))
export const BuilderContentFactsBox = () => import('../../components/builder/content/FactsBox.vue' /* webpackChunkName: "components/builder-content-facts-box" */).then(c => wrapFunctional(c.default || c))
export const BuilderContentFullWidthImage = () => import('../../components/builder/content/FullWidthImage.vue' /* webpackChunkName: "components/builder-content-full-width-image" */).then(c => wrapFunctional(c.default || c))
export const BuilderContentGallerySwiper = () => import('../../components/builder/content/GallerySwiper.vue' /* webpackChunkName: "components/builder-content-gallery-swiper" */).then(c => wrapFunctional(c.default || c))
export const BuilderContentHeroBanner = () => import('../../components/builder/content/HeroBanner.vue' /* webpackChunkName: "components/builder-content-hero-banner" */).then(c => wrapFunctional(c.default || c))
export const BuilderContentHtml = () => import('../../components/builder/content/Html.vue' /* webpackChunkName: "components/builder-content-html" */).then(c => wrapFunctional(c.default || c))
export const BuilderContentImage = () => import('../../components/builder/content/Image.vue' /* webpackChunkName: "components/builder-content-image" */).then(c => wrapFunctional(c.default || c))
export const BuilderContentLargeCallToAction = () => import('../../components/builder/content/LargeCallToAction.vue' /* webpackChunkName: "components/builder-content-large-call-to-action" */).then(c => wrapFunctional(c.default || c))
export const BuilderContentLogoGrid = () => import('../../components/builder/content/LogoGrid.vue' /* webpackChunkName: "components/builder-content-logo-grid" */).then(c => wrapFunctional(c.default || c))
export const BuilderContentPageHeader = () => import('../../components/builder/content/PageHeader.vue' /* webpackChunkName: "components/builder-content-page-header" */).then(c => wrapFunctional(c.default || c))
export const BuilderContentPartnerGrid = () => import('../../components/builder/content/PartnerGrid.vue' /* webpackChunkName: "components/builder-content-partner-grid" */).then(c => wrapFunctional(c.default || c))
export const BuilderContentProcessGrid = () => import('../../components/builder/content/ProcessGrid.vue' /* webpackChunkName: "components/builder-content-process-grid" */).then(c => wrapFunctional(c.default || c))
export const BuilderContentQuoteSlider = () => import('../../components/builder/content/QuoteSlider.vue' /* webpackChunkName: "components/builder-content-quote-slider" */).then(c => wrapFunctional(c.default || c))
export const BuilderContentTextAndIconGrid = () => import('../../components/builder/content/TextAndIconGrid.vue' /* webpackChunkName: "components/builder-content-text-and-icon-grid" */).then(c => wrapFunctional(c.default || c))
export const BuilderContentTextAndImage = () => import('../../components/builder/content/TextAndImage.vue' /* webpackChunkName: "components/builder-content-text-and-image" */).then(c => wrapFunctional(c.default || c))
export const BuilderContentTextAndImageList = () => import('../../components/builder/content/TextAndImageList.vue' /* webpackChunkName: "components/builder-content-text-and-image-list" */).then(c => wrapFunctional(c.default || c))
export const BuilderContentTextSection = () => import('../../components/builder/content/TextSection.vue' /* webpackChunkName: "components/builder-content-text-section" */).then(c => wrapFunctional(c.default || c))
export const BuilderFormRequest = () => import('../../components/builder/form/FormRequest.vue' /* webpackChunkName: "components/builder-form-request" */).then(c => wrapFunctional(c.default || c))
export const BuilderMediaFullWidthVideo = () => import('../../components/builder/media/FullWidthVideo.vue' /* webpackChunkName: "components/builder-media-full-width-video" */).then(c => wrapFunctional(c.default || c))
export const BuilderMediaImageCompare = () => import('../../components/builder/media/ImageCompare.vue' /* webpackChunkName: "components/builder-media-image-compare" */).then(c => wrapFunctional(c.default || c))
export const UiAddressFooterList = () => import('../../components/ui/address/FooterList.vue' /* webpackChunkName: "components/ui-address-footer-list" */).then(c => wrapFunctional(c.default || c))
export const UiBlogFeaturedBlogPosts = () => import('../../components/ui/blog/FeaturedBlogPosts.vue' /* webpackChunkName: "components/ui-blog-featured-blog-posts" */).then(c => wrapFunctional(c.default || c))
export const UiBlogListPosts = () => import('../../components/ui/blog/ListPosts.vue' /* webpackChunkName: "components/ui-blog-list-posts" */).then(c => wrapFunctional(c.default || c))
export const UiCareersList = () => import('../../components/ui/careers/List.vue' /* webpackChunkName: "components/ui-careers-list" */).then(c => wrapFunctional(c.default || c))
export const UiCaseStudyList = () => import('../../components/ui/case-study/List.vue' /* webpackChunkName: "components/ui-case-study-list" */).then(c => wrapFunctional(c.default || c))
export const UiCaseStudyPageViewWithTags = () => import('../../components/ui/case-study/PageViewWithTags.vue' /* webpackChunkName: "components/ui-case-study-page-view-with-tags" */).then(c => wrapFunctional(c.default || c))
export const UiElementsButton = () => import('../../components/ui/elements/Button.vue' /* webpackChunkName: "components/ui-elements-button" */).then(c => wrapFunctional(c.default || c))
export const UiForm = () => import('../../components/ui/form/Form.vue' /* webpackChunkName: "components/ui-form" */).then(c => wrapFunctional(c.default || c))
export const UiGridsAnimatedTextGrid = () => import('../../components/ui/grids/AnimatedTextGrid.vue' /* webpackChunkName: "components/ui-grids-animated-text-grid" */).then(c => wrapFunctional(c.default || c))
export const UiGridsImageGrid = () => import('../../components/ui/grids/ImageGrid.vue' /* webpackChunkName: "components/ui-grids-image-grid" */).then(c => wrapFunctional(c.default || c))
export const UiSectionsButton = () => import('../../components/ui/sections/Button.vue' /* webpackChunkName: "components/ui-sections-button" */).then(c => wrapFunctional(c.default || c))
export const UiSectionsColourPalette = () => import('../../components/ui/sections/ColourPalette.vue' /* webpackChunkName: "components/ui-sections-colour-palette" */).then(c => wrapFunctional(c.default || c))
export const UiSectionsContent = () => import('../../components/ui/sections/Content.vue' /* webpackChunkName: "components/ui-sections-content" */).then(c => wrapFunctional(c.default || c))
export const UiSectionsFactsBox = () => import('../../components/ui/sections/FactsBox.vue' /* webpackChunkName: "components/ui-sections-facts-box" */).then(c => wrapFunctional(c.default || c))
export const UiSectionsFullWidthImage = () => import('../../components/ui/sections/FullWidthImage.vue' /* webpackChunkName: "components/ui-sections-full-width-image" */).then(c => wrapFunctional(c.default || c))
export const UiSectionsFullWidthVideo = () => import('../../components/ui/sections/FullWidthVideo.vue' /* webpackChunkName: "components/ui-sections-full-width-video" */).then(c => wrapFunctional(c.default || c))
export const UiSectionsHeader = () => import('../../components/ui/sections/Header.vue' /* webpackChunkName: "components/ui-sections-header" */).then(c => wrapFunctional(c.default || c))
export const UiSectionsImage = () => import('../../components/ui/sections/Image.vue' /* webpackChunkName: "components/ui-sections-image" */).then(c => wrapFunctional(c.default || c))
export const UiSectionsImageCompare = () => import('../../components/ui/sections/ImageCompare.vue' /* webpackChunkName: "components/ui-sections-image-compare" */).then(c => wrapFunctional(c.default || c))
export const UiSectionsLargeCallToAction = () => import('../../components/ui/sections/LargeCallToAction.vue' /* webpackChunkName: "components/ui-sections-large-call-to-action" */).then(c => wrapFunctional(c.default || c))
export const UiSectionsTextAndIconGrid = () => import('../../components/ui/sections/TextAndIconGrid.vue' /* webpackChunkName: "components/ui-sections-text-and-icon-grid" */).then(c => wrapFunctional(c.default || c))
export const UiSectionsTextAndImage = () => import('../../components/ui/sections/TextAndImage.vue' /* webpackChunkName: "components/ui-sections-text-and-image" */).then(c => wrapFunctional(c.default || c))
export const UiSectionsTextAndImageList = () => import('../../components/ui/sections/TextAndImageList.vue' /* webpackChunkName: "components/ui-sections-text-and-image-list" */).then(c => wrapFunctional(c.default || c))
export const UiSectionsTextSection = () => import('../../components/ui/sections/TextSection.vue' /* webpackChunkName: "components/ui-sections-text-section" */).then(c => wrapFunctional(c.default || c))
export const UiSwipersCaseStudySwiper = () => import('../../components/ui/swipers/CaseStudySwiper.vue' /* webpackChunkName: "components/ui-swipers-case-study-swiper" */).then(c => wrapFunctional(c.default || c))
export const UiSwipersCompareSwiper = () => import('../../components/ui/swipers/CompareSwiper.vue' /* webpackChunkName: "components/ui-swipers-compare-swiper" */).then(c => wrapFunctional(c.default || c))
export const UiSwipersGallerySwiper = () => import('../../components/ui/swipers/GallerySwiper.vue' /* webpackChunkName: "components/ui-swipers-gallery-swiper" */).then(c => wrapFunctional(c.default || c))
export const UiSwipersQuoteSwiper = () => import('../../components/ui/swipers/QuoteSwiper.vue' /* webpackChunkName: "components/ui-swipers-quote-swiper" */).then(c => wrapFunctional(c.default || c))
export const UiTagsBar = () => import('../../components/ui/tags/Bar.vue' /* webpackChunkName: "components/ui-tags-bar" */).then(c => wrapFunctional(c.default || c))
export const UiBlogFeaturedBlogPostsItem = () => import('../../components/ui/blog/FeaturedBlogPosts/Item.vue' /* webpackChunkName: "components/ui-blog-featured-blog-posts-item" */).then(c => wrapFunctional(c.default || c))
export const UiBlogListPostsItem = () => import('../../components/ui/blog/ListPosts/Item.vue' /* webpackChunkName: "components/ui-blog-list-posts-item" */).then(c => wrapFunctional(c.default || c))
export const UiCaseStudyListItem = () => import('../../components/ui/case-study/List/Item.vue' /* webpackChunkName: "components/ui-case-study-list-item" */).then(c => wrapFunctional(c.default || c))
export const UiCaseStudyTagList = () => import('../../components/ui/case-study/Tag/List.vue' /* webpackChunkName: "components/ui-case-study-tag-list" */).then(c => wrapFunctional(c.default || c))
export const UiFormInputCheckbox = () => import('../../components/ui/form/input/Checkbox.vue' /* webpackChunkName: "components/ui-form-input-checkbox" */).then(c => wrapFunctional(c.default || c))
export const UiFormInputEmail = () => import('../../components/ui/form/input/Email.vue' /* webpackChunkName: "components/ui-form-input-email" */).then(c => wrapFunctional(c.default || c))
export const UiFormInputFile = () => import('../../components/ui/form/input/File.vue' /* webpackChunkName: "components/ui-form-input-file" */).then(c => wrapFunctional(c.default || c))
export const UiFormInputHidden = () => import('../../components/ui/form/input/Hidden.vue' /* webpackChunkName: "components/ui-form-input-hidden" */).then(c => wrapFunctional(c.default || c))
export const UiFormInputPhone = () => import('../../components/ui/form/input/Phone.vue' /* webpackChunkName: "components/ui-form-input-phone" */).then(c => wrapFunctional(c.default || c))
export const UiFormInputSelect = () => import('../../components/ui/form/input/Select.vue' /* webpackChunkName: "components/ui-form-input-select" */).then(c => wrapFunctional(c.default || c))
export const UiFormInputText = () => import('../../components/ui/form/input/Text.vue' /* webpackChunkName: "components/ui-form-input-text" */).then(c => wrapFunctional(c.default || c))
export const UiFormInputTextarea = () => import('../../components/ui/form/input/Textarea.vue' /* webpackChunkName: "components/ui-form-input-textarea" */).then(c => wrapFunctional(c.default || c))
export const UiFormInputUrl = () => import('../../components/ui/form/input/Url.vue' /* webpackChunkName: "components/ui-form-input-url" */).then(c => wrapFunctional(c.default || c))
export const UiCaseStudyTagListItem = () => import('../../components/ui/case-study/Tag/List/Item.vue' /* webpackChunkName: "components/ui-case-study-tag-list-item" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
